import { useSettingsSelector } from '@/hooks/settings/use-settings-selector'
import useResponsive from '@/hooks/useResponsive'
import Search, { SearchMobileButon } from './Search'
import SearchV2 from './SearchV2'

const SearchBar = () => {
	const { isMobile } = useResponsive()

	if (isMobile) {
		return <SearchMobileButon />
	}

	const searchVersion = useSettingsSelector(
		(settings) => settings.search,
		process.env.SEARCH_VERSION == 'v2' ? 2 : 1,
	)
	return searchVersion === 2 ? <SearchV2 /> : <Search />
}

export default SearchBar
