import { ProjectTree } from "../services/project.service";
import { useMetadata } from "./useMetadata"
import { useTree } from "./useTree";


export const useHiddenLayers = () => {
    const { metadata, isLoading: isMetaLoading, refetch: refetchMeta } = useMetadata()
    const { layers, isLoading: isTreeLoading, refetch: refetchTree } = useTree()
    const isLoading = isMetaLoading || isTreeLoading
    const refetch = () => void Promise.all([refetchMeta(), refetchTree()])
    const isHidden = isHiddenTree(metadata?.layers ?? {});
    const hiddenLayers = (metadata && layers) ? extractHiddenTrees(isHidden, layers.tree) : void 0
    return { isLoading, refetch, hiddenLayers }
}

const isHiddenTree = (layersMetadata: any) => (tree: ProjectTree): boolean => {
	const plugins = layersMetadata[tree.type_uid]?.plugin_data ?? {}
	const fields = Object.values(plugins).find((v: any) => v.fields)?.['fields'] ?? []
	const hideProp = fields.find((prop: any) => prop.name === '#hide')
	if (!hideProp) return false
	return Object.values(tree.plugin_data)
        .reduce((result: any[], pluginRows) => result.concat(pluginRows), [])
        .some(({ field_id, value }) => field_id == hideProp.id && value)
}

const extractHiddenTrees = (isHidden: (tree: ProjectTree) => boolean, trees: ProjectTree[]): ProjectTree[] => 
    trees.reduce((result: ProjectTree[], tree) => result.concat(
        isHidden(tree) 
            ? flatChildren(tree) 
            : extractHiddenTrees(isHidden, tree.childs)
    ), [])

const flatChildren = (tree: ProjectTree, usedIds: number[] = []): ProjectTree[] => {
    if (usedIds.includes(tree.id)) return []
    return tree.childs
		.map((child) => flatChildren(child, [...usedIds, tree.id]))
		.flat()
		.concat([tree])
}