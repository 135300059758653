import React from 'react'
import styled from 'styled-components'
import MapStatus from './stage/MapStatus'
import MapContainer from './stage/MapContainer'
import MapController from './stage/MapController'
import MapTooltip from './tooltip/MapTooltip'
import LayerSelector from '@/components/shared/map/layer-selector/LayerSelector'

const Map: React.FC = () => {
	return (
		<MapWrapper>
			<MapContainer />
			<MapStatus />
			<MapController />
			{/*<LayerSelector />*/}

			<MapTooltip />
		</MapWrapper>
	)
}

Map.whyDidYouRender = true

export default Map

const MapWrapper = styled.div`
    position: relative;
`
